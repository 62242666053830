$(document).ready(function () {

    $("#contact-dropdown").on("change", function(){
        $('body').animate({
            scrollTop: $('h2[data-title="' + this.value + '"]').offset().top
        });
    });

    if ($("#kaleidoscope-contact-form").length) {
        $("#kaleidoscope-contact-form").validate({
            submitHandler: function (form) {
                $('#kaleidoscope-contact-form button[type="submit"]').attr('disabled', true);
                $('#kaleidoscope-contact-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                form["name"] = "";
                console.log(form);
                var formId = 19;
                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response) {
        $('#kaleidoscope-contact-form button[type="submit"]').attr('disabled', false);
        $('#kaleidoscope-contact-form .alert-danger, #kaleidoscope-contact-form .fa-cog').remove();
        $('#kaleidoscope-contact-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response) {
        $('#kaleidoscope-contact-form button[type="submit"]').attr('disabled', false);
        $('#kaleidoscope-contact-form .alert-danger, #kaleidoscope-contact-form .fa-cog').remove();
        $('#kaleidoscope-contact-form').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch. Somebody will get back to you shortly</div>');
    }
});
