$(document).ready(function() {
  if ($(".search-form").length) {
    $(".search-form").validate({
      rules: {
        searchTerm: {
          required: true,
          minlength: 3
        }
      },
      submitHandler: function(form) {
        $('#search-results-container').html('<i class="fa fa-cog fa-spin fa-2x"></i>');
        $('.search-form button[type="submit"]').attr('disabled', true);
        $.ajax({
          url: "/api/search?searchTerm="+encodeURI($(form).find('input[name="searchTerm"]').val()),
          beforeSend: function(xhr){xhr.setRequestHeader('auth', authKey);},
          success: function(response) {
            $('.search-form button[type="submit"]').attr('disabled', false);
            handleResults(response.data.results);
          },
          error: function(response){
            $('.search-form button[type="submit"]').attr('disabled', false);
            $('#search-results-container').html('<p>Sorry, an error occurred, please try again</p>');
          }
        })
      }
    });
    var query = $('#query').val();
    if (query) {
      $('input[name="searchTerm"]').val(query);
      $(".search-form").submit();
    }
  }
});
function handleResults(results) {
  var html = '<ul>';
  var hasResults = false;
  var not_allowed = ['advisers'];
  console.table(results.core_results);
  results.core_results.forEach(function(item) {
    // the tild on index of allows you to use it as a bool (makes -1 falsy and index 0 truthy, to make it a true bool use !!~)
    if (item.is_deleted || !item.is_public || ~not_allowed.indexOf(item.type.unique_identifier)) {
      return;
    }
    hasResults = true;

    // This had to be added as the URL provided is wrong in some cases
    var link = '/';
    if (item.parent) {
        if (item.parent.parent) {
            link += item.parent.parent.slug+"/";
        }
        link += item.parent.slug + "/";
    } else if (item.type.unique_identifier !== 'pages') {
      link += item.type.slug + '/';
    }
    link += item.slug;
    link = link.replace(/\/\//g, '/');

    html += '<li>'
    html += '<a href="'+link+'">'
    html += '<h3 class="text-navy">'+item.title+'</h3>'
    html += '<small>'+window.location.origin + link+'</small>';
    html += '<p class="text-grey">'+(item.excerpt || '...')+'</p>';
    html += '</a>';
    html += '</li>';
  });
  html += '</ul>';

  if (!hasResults) {
    $('#search-results-container').html('<p>Sorry, no results found</p>');
    return;
  }
  $('#search-results-container').html(html);
}
