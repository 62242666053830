$(function(){

    // News page - news cards
    $('.news-content').matchHeight();

    //Catch all
    $('.matchheight').matchHeight();


    // Members stories page - Members stories card
    $('.member-stories-card h4').matchHeight();

    // catch all
    $('.col-loop h4').matchHeight();

    //Advtisers support page
    $('.advisers-content').matchHeight();

    // Member stories
    $('.member-stories-card-body').matchHeight();
    
});