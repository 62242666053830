$(document).ready(function() {
  if ($("#member-stories").length) {
    $("#member-stories .control").on("click", function() {
      var current = parseInt($("#member-stories").data("active"));
      var total = parseInt($("#member-stories").data("total"));
      var forward = $(this).hasClass("back") ? false : true;
      var next;
      if (forward) {
        next = current === total ? "1" : current + 1;
      } else {
        next = current === 1 ? total : current - 1;
      }
      $("#member-stories").data("active", next);
      $("#member-stories *[data-item]").hide();
      $('#member-stories *[data-item="'+next+'"]')
        .show()
        .css({
          display: "block"
        });
    });
  }
});
