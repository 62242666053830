$(function () {
    $('.kaleidoscope-header .hamburger').on('click', function(){
        $(this).toggleClass('is-active');

        if(!$('.kaleidoscope-header nav.mobile').hasClass('open')){
            $('.kaleidoscope-header nav.mobile').addClass('open');
        } else{
            $('.kaleidoscope-header nav.mobile').removeClass('open');
        }


    });
});
