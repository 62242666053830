$(function () {
    $('.resource-accordion .resource-accordion-header').on('click', function(){
        var sibling = $(this).siblings('.resource-accordion-body');
        var icon = $(this).children('i');
        if(!sibling.hasClass('open')){
            $(this).siblings('.resource-accordion-body').addClass('open');
            icon.removeClass('fa-plus').addClass('fa-minus');
        } else{
            $(this).siblings('.resource-accordion-body').removeClass('open');
            icon.removeClass('fa-minus').addClass('fa-plus');
        }
    });
});