$(document).ready(function() {
    if ($("#email-address-update").length) {
        $("#email-address-update").validate({
            submitHandler:function(form){
                $('#email-address-update button[type="submit"]').attr('disabled', true);
                $('#email-address-update').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 21;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#email-address-update button[type="submit"]').attr('disabled', false);
        $('#email-address-update .alert-danger, #email-address-update .fa-cog').remove();
        $('#email-address-update').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#email-address-update button[type="submit"]').attr('disabled', false);
        $('#email-address-update .alert-danger, #email-address-update .fa-cog').remove();
        $('#email-address-update').html('<div class="alert alert-primary" role="alert">That’s it. All done, thanks for letting us know.</div>');
        $("html, body").animate({
            scrollTop:0
        });
    }
});
