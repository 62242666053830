$(".complete-checkbox").on("click", function () {
    if ($(this).hasClass("is-complete")) {
        $(this).removeClass("is-complete");
        var payload = {'fields': []}
        var categoryID = $(this).attr("data-category-id");
        var resourceID = $(this).attr("data-resource-id");

        for( var i = 0; i < completed[categoryID].length; i++){
                completed[categoryID][i] = "";
        }

        completed = JSON.stringify(completed);
        payload.fields.push({'name': 'resources_complete', 'value': completed});
        LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);


        var reactionPayload = {
            context: $(".resource-page-single").attr("data-context"),
            reference_id: $(".resource-page-single").attr("data-container-id"),
            interaction: "UNCOMPLETE_RESOURCE"
        };

        LightSpeed.apiCall('reaction', reactionPayload, 'POST', null, null);



    } else {
        $(this).addClass("is-complete");

        var payload = {'fields': []};
        var categoryID = $(this).attr("data-category-id");
        var resourceID = $(this).attr("data-resource-id");
        if (completed[categoryID] == null) {
            completed[categoryID] = [];
        }
        completed[categoryID].push(resourceID);
        completed = JSON.stringify(completed);
        payload.fields.push({'name': 'resources_complete', 'value': completed});
        LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);

        var reactionPayload = {
            context: $(".resource-page-single").attr("data-context"),
            reference_id: $(".resource-page-single").attr("data-container-id"),
            interaction: "COMPLETE_RESOURCE"
        };

        LightSpeed.apiCall('reaction', reactionPayload, 'POST', null, null);
    }
});