$(function(){

    $("#homeVideo").on('show.bs.modal', function(event){
       var button = $(event.relatedTarget);
       var url = button.data('video');

       var modal = $(this);
       modal.find('iframe').attr('src', url);
    });

});