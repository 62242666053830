$(document).ready(function() {
    if ($("#cancel-form").length) {
        $("#cancel-form").validate({
            submitHandler:function(form){
                $('#cancel-form button[type="submit"]').attr('disabled', true);
                $('#cancel-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 13;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#cancel-form button[type="submit"]').attr('disabled', false);
        $('#cancel-form .alert-danger, #claim-notification .fa-cog').remove();
        $('#cancel-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#cancel-form button[type="submit"]').attr('disabled', false);
        $('#cancel-form .alert-danger, #claim-notification .fa-cog').remove();
        $('#cancel-form').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch. Our Member Services team will contact you within 5 working days.</div>');
    }
});
