$(".cpd-single .cpd-complete-checkbox").on("click", function () {
    if ($(this).hasClass("is-complete")) {
        $(this).removeClass("is-complete");
        var payload = {'fields': []}
        var cpdID = $(this).attr("data-cpd-id");

        completed = onUnComplete;

        console.log(completed);

        completed = JSON.stringify(completed);
        payload.fields.push({'name': 'cpds_complete', 'value': completed});
        LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);

    } else {
        $(this).addClass("is-complete");

        var payload = {'fields': []};
        var cpdID = $(this).attr("data-cpd-id");

        if (completed[cpdID] == null) {
            completed[cpdID] = [];
        }

        completed[cpdID].push(cpdID);
        completed = JSON.stringify(completed);

        console.log(completed);

        payload.fields.push({'name': 'cpds_complete', 'value': completed});
        LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);
    }
});