$(document).ready(function() {
    if ($("#help-you-form").length) {
        $("#help-you-form").validate({
            submitHandler:function(form){
                $('#help-you-form button[type="submit"]').attr('disabled', true);
                $('#help-you-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 24;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#help-you-form button[type="submit"]').attr('disabled', false);
        $('#help-you-form .alert-danger, #help-you-form .fa-cog').remove();
        $('#help-you-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#help-you-form button[type="submit"]').attr('disabled', false);
        $('#help-you-form .alert-danger, #help-you-form .fa-cog').remove();
        $('#help-you-form').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch, we aim to respond to you within 5working days. Alternatively you can email <a href="mailto:members@holloway.co.uk</a>,  call 0800 0931 535 (Monday – Friday 9am – 5pm) or write to us at Member services, Holloway House, 71 Eastgate Street, Gloucester GL1 1PW.</a></div>');
    }
});
