$(function () {
    $('.homepage-slider').slick({
        slidesToShow: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        prevArrow: $('.homepage-slider-wrapper .prev'),
        nextArrow: $('.homepage-slider-wrapper .next'),
        adaptiveHeight: true
    });


    // if ($(".kaleidoscope-homepage").length){
    //     var payload = {'fields':[]}
    //
    //     payload.fields.push({'name':'has_logged_in_before', 'value':1});
    //
    //     LightSpeed.apiCall('account/fields',  payload, 'PUT', null, null)
    // }


});





