$("form.has-recaptcha").on('submit', function(e){
    //e.preventDefault();

    var response = grecaptcha.getResponse();

    console.log(response);
    console.log(response.length);

    if(response.length===0){
        $(".recaptcha-error").removeClass("d-none");
        return false;
    }

    return true;

});
