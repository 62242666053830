$("#submit-test").on("click", function(){
    var totalQuestions = $(".test-card").length;
    var correctQuestions =  $(".test-card.is-correct").length;
    var incorrectQuestions = totalQuestions - correctQuestions;

    Date.prototype.getUnixTime = function() { return this.getTime()/1000|0 };
    if(!Date.now) Date.now = function() { return new Date(); }
    Date.time = function() { return Date.now().getUnixTime(); }

    var percent = (correctQuestions / totalQuestions) * 100;

    if(percent >= passRate){
        $('#testModalPass').modal('show');
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!

        var yyyy = today.getFullYear();
        if(dd < 10){
            dd='0'+dd;
        }
        if(mm < 10){
            mm="0"+mm;
        }
        var timestamp = dd+'/'+mm+'/'+yyyy;

        //var completed = {};

        var payload = {'fields': []}
        if (completed[testID] == null) {
            completed[testID] = [];
        }
        completed[testID].push(timestamp);
        completed = JSON.stringify(completed);
        payload.fields.push({'name': 'tests_complete', 'value': completed});

        console.log(payload);

        LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);


        var reactionPayload = {
            context: $(".test-single").attr("data-context"),
            reference_id: $(".test-single").attr("data-container-id"),
            interaction: "CPD_PASS"
        };

        LightSpeed.apiCall('reaction', reactionPayload, 'POST', null, null);


    } else{
        $('#testModalFail').modal('show');

        var reactionPayload = {
            context: $(".test-single").attr("data-context"),
            reference_id: $(".test-single").attr("data-container-id"),
            interaction: "CPD_FAIL"
        };

        LightSpeed.apiCall('reaction', reactionPayload, 'POST', null, null);
    }

});

$(".question-container input").on("change", function(){

    var totalQuestions = $(".test-card").length;
    var totalCheckedInputs = $(".test-card input:checked").length;

    if(totalCheckedInputs == totalQuestions){
        $('#submit-test').prop("disabled", false);
    }

    var question = $(this).attr("data-question");
    if($(this).hasClass("is-correct")){
        $("#question-" + question).addClass("is-correct");
    } else{
        $("#question-" + question).removeClass("is-correct");
    }
});
