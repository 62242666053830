$(document).ready(function() {
    if ($("#complaint-form").length) {
        $("#complaint-form").validate({
            submitHandler:function(form){
                $('#complaint-form button[type="submit"]').attr('disabled', true);
                $('#complaint-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 20;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#complaint-form button[type="submit"]').attr('disabled', false);
        $('#complaint-form .alert-danger, #complaint-form .fa-cog').remove();
        $('#complaint-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#complaint-form button[type="submit"]').attr('disabled', false);
        $('#complaint-form .alert-danger, #complaint-form .fa-cog').remove();
        $('#complaint-form').html('<div class="alert alert-primary" role="alert">We will be in touch within 5 days of receiving your complaint.<br><br>In the meantime you can read our complaints procedure <a href="https://www.holloway.co.uk/docs/download/complaints-handling-guide.pdf">here.</a></div>');
    }
});
