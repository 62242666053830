$(function () {
    $('.faq-accordion .accordion-header').on('click', function(){
        var sibling = $(this).siblings('.accordion-body');
        var icon = $(this).children('i');

        if(sibling.hasClass('open')){
            sibling.removeClass('open');
            icon.removeClass('fa-minus').addClass('fa-plus');
        } else{
            sibling.addClass('open');
            icon.removeClass('fa-plus').addClass('fa-minus');
        }
    });
});

