$(function () {
    $('.nice-select').on('click', function(){
        $(this).toggleClass('open');
    });

    $(document).on('click','.nice-select.faq-filter ul li',function(){
        var url = $(this).attr('data-value');
        window.location.href = url;
    });
});

