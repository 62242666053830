$(document).ready(function() {

    /**
     * Listens for changes on the authorised_firm_network_or_appointed_representative field
     */
    $('input[name="field:authorised_firm_network_or_appointed_representative"]').change(function() {
        if(this.value === "A directly Authorised Firm or Network") {
            $('.fca-number-wrapper').removeClass('hidden');
            $('.da-firm-or-network-number-wrapper').addClass('hidden');
            $('.ar-firms-fca-number-wrapper').addClass('hidden');
        } else if(this.value === "An appointed Representative") {
            $('.fca-number-wrapper').addClass('hidden');
            $('.da-firm-or-network-number-wrapper').removeClass('hidden');
            $('.ar-firms-fca-number-wrapper').removeClass('hidden');
        }
    });

    /**
     * Listens for changes on the wholly_owned_subsidiary field
     */
    $('input[name="field:wholly_owned_subsidiary"]').change(function() {
        if(this.value === "Yes") {
            $('.holding-company-registered-name-wrapper').removeClass('hidden');
            $('.holding-company-address-wrapper').removeClass('hidden');
        } else {
            $('.holding-company-registered-name-wrapper').addClass('hidden');
            $('.holding-company-address-wrapper').addClass('hidden');
        }
    });

    /**
     * Listens for changes on the introduce_business_to_another_firm field
     */
    $('input[name="field:introduce_business_to_another_firm"]').change(function() {
        if(this.value === "Yes") {
            $('.introduce-business-to-another-firm-details-wrapper').removeClass('hidden');
        } else {
            $('.introduce-business-to-another-firm-details-wrapper').addClass('hidden');
        }
    });

    /**
     * Listens for changes on the introductions_to_you_form_other_firms field
     */
    $('input[name="field:introductions_to_you_form_other_firms"]').change(function() {
        if(this.value === "Yes") {
            $('.introductions-to-you-form-other-firms-details-wrapper').removeClass('hidden');
        } else {
            $('.introductions-to-you-form-other-firms-details-wrapper').addClass('hidden');
        }
    });

    /**
     * Listens for changes on the have_lived_at_current_address_for_less_then_3_years field
     */

    $('input[name="field:executive_1_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 1)
    });

    $('input[name="field:executive_2_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 2)
    });

    $('input[name="field:executive_3_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 3)
    });

    $('input[name="field:executive_4_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 4)
    });

    $('input[name="field:executive_5_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 5)
    });

    $('input[name="field:executive_6_have_lived_at_current_address_for_less_then_3_years"]').change(function() {
        showHidePreviousAddress(this, 6)
    });

    /**
     * Shows or hides the previous address
     *
     * @param inputObject
     * @param index
     */
    function showHidePreviousAddress(inputObject, index) {
        if(inputObject.value === "Yes") {
            $('.previous-address-wrapper-' + index).removeClass('hidden');
        } else {
            $('.previous-address-wrapper-' + index).addClass('hidden');
        }
    }

    /**
     * Shows a new executive form on click up to 6
     */
    $(".add-executive").click(function() {
       var currentId = $(this).data('executive-id');
       var nextId = currentId + 1;
       $('.executive-'+nextId).removeClass('hidden');
       $('input[name="field:executive_'+ nextId +'_have_lived_at_current_address_for_less_then_3_years"]').prop('required', true);
       $('.remove-executive-' + nextId).removeClass('hidden');
       $('.remove-executive-' + currentId).addClass('hidden');
       $(this).addClass('hidden');
    });

    $(".remove-executive").click(function() {
        var id = $(this).data('executive-id');
        var previousId = id - 1;
        $('input[name="field:executive_'+ id +'_have_lived_at_current_address_for_less_then_3_years"]').prop('required', false);
        $('.add-executive-' + previousId).removeClass('hidden');
        // Reset fields in ths form
        clearAllInputs('.executive-'+id);
        // Hide this section
        $('.executive-'+id).addClass('hidden');
        // As long as the previous is not the first from show the button again in the previous form
        if(id !== 2) {
            $('.remove-executive-' + previousId).removeClass('hidden');
        }
    });

    /**
     * Submit the form
     */

    $("#online-registration-form").submit(function(e){
        e.preventDefault();

        //Override some values
        var name = $('input[name="name"]');
        var businessName = $('input[name="field:business_name"]');

        if(businessName.val() !== ''){
            $(name).val(businessName.val());
        }

        // Handles the member_of_service_providers options
        var memberOfServiceProviders = $('input[name="field:member_of_service_providers"]');

        var memberOfServiceProvidersOptions = getCheckboxValue([
            $('input[name="member_of_service_providers_option_1"]'),
            $('input[name="member_of_service_providers_option_2"]')
        ]);
        var memberOfServiceProvidersValue = memberOfServiceProvidersOptions.join(', ');

        memberOfServiceProviders.val(memberOfServiceProvidersValue);

        //gets the form
        var contactForm = $(this);

        //Sets the loading icon
        var submitButton = contactForm.find("*[type=submit]");
        submitButton.html('Sending &nbsp;&nbsp;<span class="fa fa-spinner fa-spin"></span>').addClass("sending");
        //Get the form ID
        var formId = contactForm.data('form-id');

        var successMessage = "Thanks for you application we will contact you shortly.";

        //Submit the data
        LightSpeed.submitContactForm(contactForm, formId, 'POST', contactFormSuccess, contactFormFail, false, successMessage);
    });

    function contactFormSuccess(res, element, message) {
        console.log('Contact form success');

        $(".response").html(message);
        element.find("*[type=submit]").hide().removeClass("sending");
        element.find('input, select, textarea').empty().val('');
        element.find('input[type=checkbox]').prop('checked', false);
        element.hide();
    }

    function contactFormFail(res, element, message) {
        console.log('Contact form failed');

        element.find("*[type=submit]").text("Re-send").removeClass("sending");
    }

    function clearAllInputs(selector) {
        $(selector).find(':input').each(function() {
            if(this.type == 'submit'){
                //do nothing
            }
            else if(this.type == 'checkbox' || this.type == 'radio') {
                this.checked = false;
            }else{
                $(this).val('');
            }
        });
    }
    
    function getCheckboxValue(checkboxs) {

        var data = [];

        checkboxs.forEach(function (checkbox) {
            if(checkbox.is(":checked")) {
                data.push(checkbox.val());
            }
        }) ;

        return data;
    }


});
