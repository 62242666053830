$(document).ready(function() {
  if ($("#adviser-contact").length) {
    $("#adviser-contact").validate({
        submitHandler:function(form){
            $('#adviser-contact button[type="submit"]').attr('disabled', true);
            $('#adviser-contact').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
            var formId = 10;

            ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

        }
    });
  }

    function handleFormError(response){
        $('#adviser-contact button[type="submit"]').attr('disabled', false);
        $('#adviser-contact .alert-danger, #adviser-contact .fa-cog').remove();
        $('#adviser-contact').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#adviser-contact button[type="submit"]').attr('disabled', false);
        $('#adviser-contact .alert-danger, #adviser-contact .fa-cog').remove();
        $('#adviser-contact').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch. Somebody will get back to you shortly</div>');
        $("html, body").animate({
            scrollTop:0
        }); 
    }
});
