if($(".my-profile").length){
    $("#edit-profile-button").on("click", function(e){
        $("input:disabled").removeAttr("disabled");
        $("#save-profile-container").show();
        e.preventDefault();
    });


    $("#profile-form").on("submit", function(e){

        var payload = {};
        var payloadFields = {'fields': []};

        payload.title = 'n/a';
        payload.first_name = $("*[name=first_name]").val();
        payload.last_name = $("*[name=last_name]").val();
        payload.email_address = $("*[name=email_address]").val();
        payload.home_phone_number = $("*[name=telephone_number]").val();

        payloadFields.fields.push({'name': 'yes_you_can_send_me_updates_via_post', 'value': $("*[name=yes_you_can_send_me_updates_via_post]:checked").length});
        payloadFields.fields.push({'name': 'yes_you_can_send_me_email_updates', 'value': $("*[name=yes_you_can_send_me_email_updates]:checked").length});
        payloadFields.fields.push({'name': 'yes_im_happy_for_you_to_telephone_me', 'value': $("*[name=yes_im_happy_for_you_to_telephone_me]:checked").length});

        payloadFields.fields.push({'name': 'yes_you_can_send_me_sms_updates', 'value': $("*[name=yes_you_can_send_me_sms_updates]:checked").length});
        payloadFields.fields.push({'name': 'no_marketing_information', 'value': $("*[name=no_marketing_information]:checked").length});

        payloadFields.fields.push({'name': 'postcode', 'value': $("*[name=postcode]").val()});
        payloadFields.fields.push({'name': 'company_name', 'value': $("*[name=company_name]").val()});
        payloadFields.fields.push({'name': 'fca_number', 'value': $("*[name=fca_number]").val()});

        LightSpeed.apiCall('account/fields', payloadFields, 'PUT', null, null);

        LightSpeed.apiCall('account', payload, 'PUT', saveError(), saveSuccess());

        e.preventDefault();
    });

    function saveSuccess()
    {
        $("input").prop("disabled", true);
        $("#save-profile-container").hide();

        $(".alert-success").addClass("show");
        $(".alert-success").show();

        $(".alert-danger").addClass("hide");
        $(".alert-danger").hide();
    }

    function saveError()
    {
        $(".alert-success").addClass("hide");
        $(".alert-success").hide();
        $(".alert-danger").addClass("show");
        $(".alert-danger").show();
    }


}


if($(".first-login-mark-complete").length!==0){

    var payload = {
        "fields": [
            {
                "name": "has_logged_in_before",
                "value": 1
            }
        ]
    };

    LightSpeed.apiCall('account/fields', payload, 'PUT', null, null);
}
