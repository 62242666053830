$(document).ready(function() {
    if ($("#save-the-trees-form").length) {
        $("#save-the-trees-form").validate({
            submitHandler:function(form){
                $('#save-the-trees-form button[type="submit"]').attr('disabled', true);
                $('#save-the-trees-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 22;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#save-the-trees-form button[type="submit"]').attr('disabled', false);
        $('#save-the-trees-form .alert-danger, #save-the-trees-form .fa-cog').remove();
        $('#save-the-trees-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#save-the-trees-form button[type="submit"]').attr('disabled', false);
        $('#save-the-trees-form .alert-danger, #save-the-trees-form .fa-cog').remove();
        $('#save-the-trees-form').html('<div class="alert alert-primary" role="alert">Thank you for helping us save the trees.</div>');
        $("html, body").animate({
            scrollTop:0
        });
    }
});
