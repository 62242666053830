$(document).ready(function() {
  if ($("#adviser-registration").length) {
    $('select[name="field:hear_about"]').on("change", function() {
      if ($(this).val() === "Other") {
        $("#hear-about-us-other").show();
      } else {
        $("#hear-about-us-other").hide();
      }
    });
    $("#adviser-registration").validate({
        submitHandler:function(form){
          $('#adviser-registration button[type="submit"]').attr('disabled', true);
          $('#adviser-registration').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
            var formId = 11;
            ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

        }
    });
  }
  function handleFormError(response){
    $('#adviser-registration button[type="submit"]').attr('disabled', false);
    $('#adviser-registration .alert-danger, #adviser-registration .fa-cog').remove();
    $('#adviser-registration').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
}

function handleFormSuccess(response){
    $('#adviser-registration button[type="submit"]').attr('disabled', false);
    $('#adviser-registration .alert-danger, #adviser-registration .fa-cog').remove();
    $('#adviser-registration').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch. Somebody will get back to you shortly</div>');
}
});
