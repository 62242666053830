$(document).ready(function () {
    if ($(".register-page").length) {
        $("input:checkbox[name='fields[no_marketing_information]']").on("change", function () {
            var checkboxes = $(this).closest('form').find(':checkbox').not($(this));
            checkboxes.prop('checked', false);
        });

        $("input:checkbox:not([name='fields[no_marketing_information]'])").on("change", function () {
            $("input[name='fields[no_marketing_information]']").prop('checked', false)
        });
    }


    if ($(".my-profile").length) {
        $("input:checkbox[name='no_marketing_information']").on("change", function () {
            var checkboxes = $(this).closest('form').find(':checkbox').not($(this));
            checkboxes.prop('checked', false);
        });

        $("input:checkbox:not([name='no_marketing_information'])").on("change", function () {
            $("input[name='no_marketing_information']").prop('checked', false)
        });
    }
});