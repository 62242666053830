$(document).ready(function() {
  if ($("#claim-notification").length) {
    $("#claim-notification").validate({
        submitHandler:function(form){
            $('#claim-notification button[type="submit"]').attr('disabled', true);
            $('#claim-notification').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
            var formId = 12;

            ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

        }
    });
  }

    function handleFormError(response){
        $('#claim-notification button[type="submit"]').attr('disabled', false);
        $('#claim-notification .alert-danger, #claim-notification .fa-cog').remove();
        $('#claim-notification').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#claim-notification button[type="submit"]').attr('disabled', false);
        $('#claim-notification .alert-danger, #claim-notification .fa-cog').remove();
        $('#claim-notification').html('<div class="alert alert-primary" role="alert">Thank you for getting in touch. Somebody will get back to you shortly</div>');
    }
});
