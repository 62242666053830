$(function() {
    // Open external links in new window. Also adds accessibility tags, and PDF Download option.
    var my_dom = window.location.hostname;
    if (my_dom.indexOf("www.") == 0) {
        my_dom = my_dom.substr(4);
    }
    $("a[href^='http://'],a[href^='https://']").each(function(){
        var this_dom = this.hostname;
        if (this_dom.indexOf("www.") == 0) {
            this_dom = this_dom.substr(4);
        }
        if (this_dom != my_dom) {
            $(this).attr("target", "_blank");
            if($(this).find(".sr-only").length==0){
                $(this).append(' <span class="sr-only">Opens in a new window</span>');
            }else{
                $(this).find(".sr-only").append(' - Opens in a new window');
            }
        }
    });

    $("a").each(function(){
        if($(this)[0].hasAttribute('href')) {
            if ($(this).attr('href').indexOf('.pdf') != -1) {
                $(this).attr("target", "_blank").append('<span class="sr-only">Opens in new window</span>');
                /*if($('.get-pdf-link').length==0){
                    $(this).after('<a href="https://get.adobe.com/uk/reader/" class="get-pdf-link sr-only"><span class="sr-only">Download acrobat reader</span></a>');
                }*/
            }
        }
    });

    var altCheck = setInterval(function() {
        var count = 0
        $("img").each(function () {
            if ( (typeof $(this).attr('alt') === 'undefined')) {
                $(this).attr("alt", 'layout');
                count ++;
            }
        });

        if(count==0){
            clearInterval(altCheck);
        }

    }, 2000);

    // Search

    $(".search-results").each(function(){
        searchTerm = $(this).data('search');
        if(searchTerm!=''){
            apiCall('search', {searchTerm:searchTerm}, 'GET', handleSearchResults);
        }
    });

    var player = new Plyr('.player', {
        // loadSprite: true,
        // iconUrl: '/hol/images/kaleidoscope/video-play-button.svg'
    });

    $(function () {
        $('[data-toggle="popover"]').popover()
    })


    $('#downloadPDF').click(function (e) {
        $('#printModal').modal('hide');
        setTimeout(
            function () {
                var doc = new jsPDF();
                window.html2canvas = html2canvas;
                var canvas = html2canvas(document.querySelector("body"));

                    document.body.appendChild(canvas);
                    var width = canvas.width;
                    var height = canvas.height;

                    //set the orientation
                    if(width > height){
                        pdf = new jsPDF('l', 'px', [width, height]);
                    }
                    else{
                        pdf = new jsPDF('p', 'px', [height, width]);
                    }
                    //then we get the dimensions from the 'pdf' file itself
                    width = pdf.internal.pageSize.getWidth();
                    height = pdf.internal.pageSize.getHeight();
                    pdf.addImage(canvas, 'PNG', 0, 0,width,height);
                    pdf.save("download.pdf");

                    canvas.remove();
            }, 1000);
        e.preventDefault();
    });


    if($(".resource-page-single").length){
        var payload = {
            context: $(".resource-page-single").attr("data-context"),
            reference_id: $(".resource-page-single").attr("data-container-id"),
            interaction: "VIEW"
        };

        LightSpeed.apiCall('reaction', payload, 'POST', null, null);
    }


    $(".js-download-file").on("click", function(e){
        var payload = {
            context: $(this).attr("href"),
            reference_id: $(this).attr("data-container-id"),
            interaction: "DOWNLOAD"
        };

        LightSpeed.apiCall('reaction', payload, 'POST', null, null);
    });

    $(".cookie-preferences-edit button").click(function(){
        $(this).parent().fadeOut();
        $(".cookie-flyout").fadeIn();
    });

   var logo = document.getElementById("logo");
   var homeTextLink = document.querySelectorAll(".home a");
   homeTextLink[1].addEventListener("click", function(e) {
       sessionStorage.removeItem("modalReturn");
       e.preventDefault();
       window.location = "/";
   });

   logo.addEventListener("click", function(e) {
       e.preventDefault();
       sessionStorage.setItem("modalReturn", "true");
       window.location = "/";
   })

});
