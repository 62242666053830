$(document).ready(function() {
    if ($("#post-vote-form").length) {
        $("#post-vote-form").validate({
            submitHandler:function(form){
                $('#post-vote-form button[type="submit"]').attr('disabled', true);
                $('#post-vote-form').append('<i class="fa fa-cog fa-spin fa-2x"></i>');
                var formId = 25;

                ls.submitContactForm(form, formId, 'POST', handleFormSuccess, handleFormError);

            }
        });
    }

    function handleFormError(response){
        $('#post-vote-form button[type="submit"]').attr('disabled', false);
        $('#post-vote-form .alert-danger, #post-vote-form .fa-cog').remove();
        $('#post-vote-form').append('<div class="alert alert-danger" role="alert">Sorry, there was a problem submitting the form, please try again.</div>');
    }

    function handleFormSuccess(response){
        $('#post-vote-form button[type="submit"]').attr('disabled', false);
        $('#post-vote-form .alert-danger, #post-vote-form .fa-cog').remove();
        $('#post-vote-form').html('<div class="alert alert-primary" role="alert">Thank you, your AGM invitation will be sent by post.</div>');
        $("html, body").animate({
            scrollTop:0
        });
    }
});
